import React from 'react'
import Typing from 'react-typing-animation'

export default function ContactBody(props) {

  const tSpeed = 5;

  return (
    <div className="sectiongroup sectionGpParent">
      <div className="contactWrap in">
        <Typing speed={tSpeed} hideCursor={true}>
          <h2>Get in touch.</h2>
        </Typing>
        <a href="emailto:alexson.c@gmail.com" className="emailLink">alexson.c@gmail.com</a>
        <div className="snswrap">
          <div className="row">
            <span className="bkL">Twitter</span>
            <a href="https://twitter.com/AlexsonChu" target="_blank" rel="noreferrer" className="bkR">@alexsonchu</a>
          </div>
          <div className="row">
            <span className="bkL">Instagram</span>
            <a href="https://www.instagram.com/alexsonchu/" target="_blank" rel="noreferrer" className="bkR">@alexsonchu</a>
          </div>
          <div className="row xrt">
            <span className="bkL">XRT</span>
            <a href="https://xrt.world/" target="_blank" rel="noreferrer" className="bkR">xrt.world</a>
          </div>
        </div>
      </div>
    </div>
  )
}